import { Component, Inject } from '@angular/core';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface Task {
    name: string;
    completed: boolean;
    link: string;
    key: string;
    params?: any;
}
@Component({
    selector: 'app-required-settings',
    templateUrl: './required-settings.component.html',
    styleUrls: ['./required-settings.component.scss'],
})
export class RequiredSettingsComponent extends BaseComponent {

    company: any;
    requiredSettingsData: any;
    tasks: Array<Task>;
    allComplete = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private matDialog: MatDialog
    ) {
        super();
        this.company = data.company;
        this.requiredSettingsData = data.requiredSettingsData;
        this.tasks = [
            {
                key: 'questions',
                name: 'companies.general-corporate-data',
                completed: false,
                link: `cockpit/company-master-data/base-data/${data.companyId}`,
                params: { fragment: 'general' },
            },
            {
                key: 'address',
                name: 'employees.address',
                completed: false,
                link: `cockpit/company-master-data/base-data/${data.companyId}`,
                params: { fragment: 'address' },
            },
            {
                key: 'wageAccountingSystem',
                name: 'payroll-bookkeeping.fields.wage-accounting-system',
                completed: false,
                link: 'bookkeeping-suggestions/payroll-bookkeeping',
                params: { fragment: 'wageAccountingSystem' },
            },
            {
                key: 'deadlineForBookingSuggestions',
                name: 'payroll-bookkeeping.fields.deadline-for-booking-suggestions',
                completed: false,
                link: 'bookkeeping-suggestions/payroll-bookkeeping',
                params: { fragment: 'deadlineForBookingSuggestions' },
            },
            {
                key: 'differentMinimumWage',
                name: 'payroll-bookkeeping.titles.different-minimum-wage',
                completed: false,
                link: 'bookkeeping-suggestions/payroll-bookkeeping',
                params: { fragment: 'differentMinimumWage' },
            },
            {
                key: 'collectiveAgreement',
                name: 'payroll-bookkeeping.titles.collective-agreement',
                completed: false,
                link: 'bookkeeping-suggestions/payroll-bookkeeping',
                params: { fragment: 'collectiveAgreement' },
            },
            {
                key: 'wageTypesTaxFree',
                name: 'bookkeeping-keys.wage-types-taxfree',
                completed: false,
                link: 'bookkeeping-suggestions/bookkeeping-keys',
                params: { fragment: 'wageTypesTaxFree' },
            },
            {
                key: 'wageTypesTaxable',
                name: 'bookkeeping-keys.wage-types-taxable',
                completed: false,
                link: 'bookkeeping-suggestions/bookkeeping-keys',
                params: { fragment: 'wageTypesTaxable' },
            },
            {
                key: 'otherServices',
                name: 'bookkeeping-keys.other-services',
                completed: false,
                link: 'bookkeeping-suggestions/bookkeeping-keys',
                params: { fragment: 'otherServices' },
            },
            {
                key: 'services',
                name: 'bookkeeping-keys.services',
                completed: false,
                link: 'bookkeeping-suggestions/bookkeeping-keys',
                params: { fragment: 'services' },
            },
        ];
        this.tasks.forEach((item) => {
            item.completed = !!this.requiredSettingsData[item.key];
            item.name = this.translateService.instant(item.name);
        });
    }
    changePage(link, params) {
        this.navigate([link], params);
        this.matDialog.closeAll();
    }

    closeModal() {
        this.matDialog.closeAll();
    }

}
