/* src/app/modules/company/optins/choose-importation-file/choose-importation-file.component.scss */
.btn-close-modal {
  margin-right: 0;
  margin-top: -5px;
  float: right;
}
.action-label {
  font-size: 18px;
  margin-top: -1px;
  margin-left: 6px;
}
.select-file-input {
  display: none;
}
.icon .btn-muted {
  cursor: pointer;
}
.file-name {
  font-weight: 700;
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
}
.delete-file-icon {
  cursor: pointer;
  margin-top: 4%;
  color: #5b6c80;
  margin-left: 7px;
}
::ng-deep .mat-horizontal-stepper-header-container {
  width: 60%;
  margin: auto;
}
::ng-deep .mat-horizontal-stepper-content:first-child {
  margin-top: 15px;
}
.settings-span {
  cursor: pointer;
  color: blue;
}
.steps {
  color: #275fa1;
  font-weight: 600;
}
.step-title {
  font-size: 20px;
}
.step-name {
  font-size: 14px;
}
@media screen and (max-width: 1366px) and (max-height: 768px) {
  .choose-importation-stepper ::ng-deep .mat-horizontal-stepper-header-container .mat-step-header {
    height: 50px;
  }
  .choose-importation-stepper ::ng-deep .upload-file-container {
    height: 140px !important;
  }
  .choose-importation-stepper .action-buttons {
    margin-top: 1rem !important;
  }
}
/*# sourceMappingURL=choose-importation-file.component-QCO2J4JR.css.map */
