<div class="d-flex justify-content-between align-items-center mb-5 mt-2">
    <finstein-search-all-table
        [table]="optinApprovedList"
        [rows]="items"
        searchPlaceholder="employees.search-items"
    ></finstein-search-all-table>
    <button
        type="button"
        class="btn btn-outline-primary rounded d-inline-flex"
        [ngClass]="{
            'btn-import-data': getNewsApprovedOptins() && !isViewMode
        }"
        (click)="openOptinSettingModal()"
        [disabled]="disableImport || !hasAnyApprovedOptin() || isViewMode || currentSystemIsBlockedForImport()"
        translate
    >
        <span class="material-icons mr-2">file_upload</span>
        optins.import-data.title
    </button>
</div>

<hr />

<ngx-datatable
    #optinApprovedList
    class="material expandable"
    columnMode="force"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="60"
    [rows]="items"
    [loadingIndicator]="loadingIndicator"
>
    <ngx-datatable-column
        [name]="'users.firstName' | translate"
        prop="firstName"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'users.lastName' | translate"
        prop="lastName"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.email' | translate"
        prop="email"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'optins.birthday' | translate"
        prop="birthday"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.status' | translate"
        cellClass="d-inline-flex align-items-center justify-content-center"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <finstein-datatable-status-column
                [customStatusValue]="getStatusValue(row)"
                [customStatusTooltip]="getStatusValue(row)"
                [row]="row"
            ></finstein-datatable-status-column>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.actions' | translate"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="true"
        cellClass="datatable-body-cell-buttons"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="row align-items-center">
                <div
                    class="issues"
                    *ngIf="row.errorReport && errorReportNotInExportStatusReasons(row)"
                    #popoverHook="ngbPopover"
                    [ngbPopover]="errorReport"
                    triggers="mouseenter:mouseleave"
                    container="body"
                    placement="left"
                    popoverClass="approved-error-popover"
                >
                    <span class="material-icons-outlined warning-icon">
                        warning
                    </span>
                </div>
                <ng-template #errorReport>
                    <div class="not-eligible-popover-container">
                        <!-- <div class="popover-header pl-4">
                            <span class='material-icons white'>info</span>
                        </div> -->
                        <div class="popover-content">
                            <p
                                class="mb-0"
                                [innerHTML]="row.errorReport | translate"
                            ></p>
                        </div>
                    </div>
                </ng-template>
                <finstein-datatable-buttons-column
                    [buttons]="buttons"
                    [row]="row"
                ></finstein-datatable-buttons-column>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<finstein-search-column
    [table]="optinApprovedList"
    [rows]="items"
></finstein-search-column>

<finstein-datatable-suport [table]="optinApprovedList">
</finstein-datatable-suport>
