import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TagInputModule } from 'ngx-chips';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ConfirmDialogModule } from '../../../core/components/confirm-dialog/confirm-dialog.module';
import { PaginationModule } from '../../../core/components/pagination/pagination.module';
import { EllipsifyModule } from '../../../core/directives/ellipsify/ellipsify.module';
import { FinsteinTooltipModule } from '../../../core/directives/finstein-tooltip/finstein-tooltip.module';
import { ApprovePendingChangesModule } from '../approve-pending-changes/approve-pending-changes.module';
import { TableConsultingProgressComponent } from './table-consulting-progress.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ConfirmDialogModule,
        MatDialogModule,
        FinsteinTooltipModule,
        UiSwitchModule,
        TagInputModule,
        NgSelectModule,
        PaginationModule,
        NgOptionHighlightModule,
        NgCircleProgressModule,
        EllipsifyModule,
        MatCheckboxModule,
        MatRippleModule,
        ApprovePendingChangesModule,
        MatSortModule,
        NgxSpinnerModule,
        MatTooltipModule,
    ],
    declarations: [TableConsultingProgressComponent],
    exports: [TableConsultingProgressComponent],
})
export class TableConsultingProgressModule {}
