import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/core/abstractions/base.component';


const ONLY_NO_CONVERSION_ERROR = [
	'messages.employee.minimum-wage-not-reached-value',
	'messages.employee.maximum-age-to-use-the-consultation-process',
];

const ERROR_VIDEOS = [
	'employee-with-leaving-date',
	'minimum-wage-not-reached-value'
];

@Component({
	selector: 'app-not-eligible-list-modal',
	templateUrl: './not-eligible-list-modal.component.html',
	styleUrls: ['./not-eligible-list-modal.component.scss']
})
export class NotEligibleListModalComponent extends BaseComponent implements OnInit {

	list: [];

    openedPopoverClass: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private matDialog: MatDialog

	) {
		super();
		this.list = data.items;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	closeModal() {
		this.matDialog.closeAll();
	}

	getErrorTitle(error: string): string {
		const reason = this.translate(error);
		return this.translate('messages.employee.not-eligible.title', {
			reason,
		});
	}

	getErrorText(error: string) {
		return ONLY_NO_CONVERSION_ERROR.includes(error)
			? 'messages.employee.not-eligible.no-conversion-text'
			: 'messages.employee.not-eligible.no-conversion-no-increase-text';
	}

	getErrorContactText(error: string) {
		return ERROR_VIDEOS.includes(error.split('.').at(-1)) ?
			'messages.employee.not-eligible.contact-text-with-video' : 'messages.employee.not-eligible.contact-text';
	}

	checkIfErrorHasVideo(error: string) {
		return ERROR_VIDEOS.includes(error?.split('.').at(-1));
	}

    getVideoName(row) {
        return row?.split('.').at(-1);
    }

    onHover(item: string) {
        this.openedPopoverClass = item.split('.').at(-1);
    }

    getPopoverClass(item: string) {
        return `not-eligible-error-popover ${item.split('.').at(-1)}`;
    }

    disablePopover(item: string) {
        return this.openedPopoverClass !== item.split('.').at(-1);
    }
}
