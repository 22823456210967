/* src/app/modules/company/optins/list/approved/optins-list-approved.component.scss */
.rounded {
  border-radius: 20px !important;
}
.btn-import-data {
  animation: pulse-blue 2s infinite;
}
@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(7, 96, 239, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(7, 96, 239, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(7, 96, 239, 0);
  }
}
.issues {
  margin-left: -29px;
  margin-right: 6px;
  width: 30px;
  font-size: 1.8rem;
}
.issues span {
  z-index: 2;
  border-radius: 100%;
  animation: pulse 2s infinite;
}
/*# sourceMappingURL=optins-list-approved.component-XHJKFSUA.css.map */
