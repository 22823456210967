/* src/app/modules/company/optins/list/pendent/optins-list-pendent.component.scss */
.col-status ::ng-deep .ng-select-container {
  border: none !important;
  background-color: transparent !important;
  color: #333 !important;
}
.warning-icon {
  color: rgba(255, 193, 7, 0.7490196078);
  height: 50% !important;
}
.select-status {
  white-space: normal !important;
}
/*# sourceMappingURL=optins-list-pendent.component-SPOQAG4I.css.map */
