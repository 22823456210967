import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, tap } from 'rxjs/operators';
import { ButtonAction } from 'src/app/shared/components/datatable-utils/components/buttons-column/buttons-column.component';
import {
    EMPLOYEES_COLLECTION,
    OP_EQUALS,
    OP_IN,
    REJECT_OPTIN_FUNCTION,
    REMOVE_OPTIN_FUNCTION,
    STATUS_INACTIVE,
    STATUS_LABELS,
} from '../../../../../app.constants';
import { BaseListComponent } from '../../../../../core/abstractions/base-list.component';
import { EmployeeStatusEnum } from '../../../../../shared/enums/employee-status.enum';
import { FilterModel } from '../../../../../shared/models/filter.model';
import { OptinsService } from '../../optins.service';
import { PendentProgressModalComponent } from '../pendent-progress-modal/pendent-progress-modal.component';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

@Component({
    selector: 'app-optins-list-pendent',
    templateUrl: './optins-list-pendent.component.html',
    styleUrls: ['./optins-list-pendent.component.scss'],
})
export class OptinsListPendentComponent
    extends BaseListComponent
    implements OnInit, OnDestroy
{
    selectedValue: string;
    @ViewChild('optinPendentList') table: DatatableComponent;

    @Input() isViewMode = false;

    statusOptions = [
        {
            key: 'optins.status-options.employee-and-email-know',
            text: this.translateService.instant(
                'optins.status-options.employee-and-email-know'
            ),
        },
        {
            key: 'optins.status-options.employee-know-email-unknow',
            text: this.translateService.instant(
                'optins.status-options.employee-know-email-unknow'
            ),
        },
        {
            key: 'optins.status-options.employee-know-not-made-request',
            text: this.translateService.instant(
                'optins.status-options.employee-know-not-made-request'
            ),
        },
        {
            key: 'optins.status-options.employee-unknow',
            text: this.translateService.instant(
                'optins.status-options.employee-unknow'
            ),
        },
    ];

    buttons: Array<ButtonAction> = [
        {
            icon: 'create',
            onClick: (item) => this.editPendentOptin(item),
            tooltip: 'tooltips.edit',
            isDisabled: (item) =>  this.isViewMode
        },
        {
            icon: 'delete',
            onClick: (item) => this.removeOptin(item),
            isDisabled: (item) =>
                (item.statusDescription &&
                    item.statusDescription === 'EMPLOYEE_AND_EMAIL_KNOW') ||
                this.isViewMode,
            tooltip: 'tooltips.delete',
            confirmActionBeforeClick: (item: any, isDisabled?: boolean) => true,
            getConfirmActionText: (item: any, isDisabled?: boolean) =>
                item.status !== STATUS_INACTIVE
                    ? 'operations.disable.confirmation'
                    : 'operations.enable.confirmation',
        },
    ];

    selectedDescription: string;

    constructor(
        private optinService: OptinsService,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    getFilter() {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_IN,
                value: [EmployeeStatusEnum.VERIFICATION_PENDING],
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: this.userService.companyId,
            },
        ];
        return filter;
    }

    getCollectionURL() {
        return EMPLOYEES_COLLECTION;
    }

    getRouterURL() {
        return 'optins';
    }

    reject(optinEmail) {
        this.spinner.show();
        this.service
            .callFunction([optinEmail], REJECT_OPTIN_FUNCTION, {
                type: 'callable',
                httpMethod: 'POST',
            })
            .subscribe({
                next: () => {
                    this.ngZone.run(() => {
                        this.spinner.hide();
                        this.notification.success(
                            'messages.success-reject-optin-list'
                        );
                    });
                },
                error: (err) => {
                    this.ngZone.run(() => {
                        this.spinner.hide();
                        this.notification.error(
                            'messages.error-records-not-processed'
                        );
                        if (err.message) {
                            this.fireLogginService.sendErrorLog(
                                `An error occurred while rejecting optins with function [${REJECT_OPTIN_FUNCTION}], details: ${err.message}`
                            );
                        } else {
                            this.fireLogginService.sendErrorLog(
                                `An error occurred while rejecting optins with function [${REJECT_OPTIN_FUNCTION}], details: ${JSON.stringify(
                                    err
                                )}`
                            );
                        }
                    });
                }
            });
    }

    removeOptin(item) {
        this.spinner.show();
        this.optinService
            .removeOptin(item.id)
            .pipe(
                map((data: { message: string }) => data.message),
                tap(() => this.spinner.hide()),
                catchError(() => this.spinner.hide())
            )
            .subscribe(
                () => this.notification.disableSuccess(),
                (err) => {
                    if (err) {
                        this.notification.error(err);
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while disabling optin with function [${REMOVE_OPTIN_FUNCTION}], details: ${err}`
                        );
                    } else {
                        this.notification.error(
                            `An error occurred while updating optin status`
                        );
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while disabling optin with function [${REMOVE_OPTIN_FUNCTION}], details: ${JSON.stringify(
                                err
                            )}`
                        );
                    }
                }
            );
    }

    editPendentOptin(item) {
        this.navigate([this.getRouterURL(), 'update', item.id]);
    }

    selectStatusDescription(event) {
        this.selectedDescription = event?.key
            .replace('optins.status-options.', '')
            .replaceAll('-', '_')
            .toUpperCase();
    }

    selectPlaceholderValue() {
        return this.translateService.instant(
            'optins.status-options.select-appropriate-status'
        );
    }

    confirmSelectedOptinEmailStatus() {
        this.dialog.open(PendentProgressModalComponent, {
            maxWidth: '60vw',
            minWidth: '45vw',
            data: {
                selectedOptins: this.itemsSelected,
                selectedDescription: this.selectedDescription
            }
        }).afterClosed().subscribe(
            () => {
                this.selectedValue = undefined;
                this.itemsSelected = [];
            }
        );
    }

    hasAnyPendentOptinSelected() {
        return this.itemsSelected.length;
    }

    getCustomStatusTooltip(row: any) {
        return STATUS_LABELS[row.statusDescription];
    }

    protected readonly SelectionType = SelectionType;
}
