/**
 * Splits a full street address into street name and house number.
 *
 * This function takes a full street address as input and separates
 * it into the street name and the house number.
 *
 * For example, given the address 'Langenstück 16a',
 * it will return ['Langenstück', '16a'].
 *
 * @param {string} address - The full street address to be split, e.g., 'Langenstück 16a'.
 * @returns { [string, string] } An array where the first element is the street name and
 *                           the second element is the house number.
 */
export function splitAddress(address: string): string[] {
    const trimmedAddress = address.trim();
    const lastSpaceIndex = trimmedAddress.lastIndexOf(' ');
    if (lastSpaceIndex === -1) {
        return [trimmedAddress, ''];
    }
    const street = trimmedAddress.slice(0, lastSpaceIndex).trim();
    const number = trimmedAddress.slice(lastSpaceIndex + 1).trim();
    if (!/^\d/.test(number)) {
        return [trimmedAddress, ''];
    }
    return [street, number];
}



