<div class="d-flex justify-content-between align-items-center mb-5 mt-2">
    <finstein-search-all-table
        [table]="optinCompletedList"
        [rows]="items"
        searchPlaceholder="employees.search-items"
    ></finstein-search-all-table>

    <div>
        <button
            type="button"
            class="btn btn-muted rounded mr-4"
            (click)="sendToDataExport()"
            [disabled]="!hasAnyImportedOptinSelected() || isViewMode"
            translate
        >
            optins.back-to-data-export
        </button>
        <button
            type="button"
            class="btn btn-outline-primary rounded"
            (click)="sendOptinsToConsultancy()"
            [disabled]="!hasAnyImportedOptinSelected() || isViewMode"
            translate
        >
            optins.read-to-consultancy
        </button>
    </div>
</div>

<hr />

<ngx-datatable
    #optinCompletedList
    class="material expandable"
    columnMode="force"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="50"
    [rows]="items"
    [loadingIndicator]="loadingIndicator"
    [selected]="itemsSelected"
    [selectionType]="SelectionType.checkbox"
    [selectAllRowsOnPage]="true"
    (select)="onSelectItem($event)"
>
    <ngx-datatable-column
        [width]="15"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        [headerCheckboxable]="!isViewMode"
        [checkboxable]="!isViewMode"
        cellClass="datatable-body-cell-checkbox"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'users.firstName' | translate"
        prop="firstName"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'users.lastName' | translate"
        prop="lastName"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.email' | translate"
        prop="email"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'optins.birthday' | translate"
        prop="birthday"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.status' | translate"
        cellClass="d-inline-flex align-items-center justify-content-center"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <finstein-datatable-status-column
                [customStatusValue]="getStatusValue(row)"
                [customStatusTooltip]="getStatusValue(row)"
                [row]="row"
            ></finstein-datatable-status-column>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.actions' | translate"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="true"
        cellClass="datatable-body-cell-buttons"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <finstein-datatable-buttons-column
                [buttons]="buttons"
                [row]="row"
            ></finstein-datatable-buttons-column>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<finstein-search-column
    [table]="optinCompletedList"
    [rows]="items"
></finstein-search-column>

<finstein-datatable-suport [table]="optinCompletedList">
</finstein-datatable-suport>
