<div class="row">
    <div class="col-10">
        <h1 class="pl-3" mat-dialog-title>
            <span translate>optins.import-data.title</span>
            <span *ngIf="currentAccountingSystem" class="ml-2 badge badge-info custom-badge-bookkeeping-keys" >
                {{translate('wage-accounting-systems.' + currentAccountingSystem?.type?.toLowerCase())}}
            </span>
        </h1>
    </div>
    <div class="col-2" *ngIf="!importingData">
        <button tabindex="-1" type="button" class="icon btn btn-muted btn-close-modal" [mat-dialog-close]="false"
                (click)="closeDialog()"
                translate>
            <i class="material-icons">close</i>
        </button>
    </div>
</div>

<div class="text-center" mat-dialog-content *ngIf="!importingData">
    <mat-horizontal-stepper class="choose-importation-stepper" [linear]="true">

        <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
        </ng-template>

        <mat-step label="{{'optins.import-data.personal-data' | translate}}">
            <p class="text-justify pl-3 pr-3 mt-0">
                <span translate [translateParams]="fileType">optins.import-data.additional-information</span>
                <span class="settings-span" (click)="goToSettings()" translate>
                    optins.import-data.additional-information-system-settings
                </span>
            </p>

            <div class="row padding mt-6 mb-6 justify-content-center">
                <app-drag-and-drop-upload-file
                    #personalFile
                    (fileSelected)="handleFileSelect($event, 1)">
                </app-drag-and-drop-upload-file>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="row ml-1 float-right mt-6 action-buttons">
                        <button type="button" mat-button matStepperNext
                                class="btn btn-primary"
                                [disabled]="!personalDataFile"
                                translate>
                            system.next
                        </button>
                    </div>
                </div>
            </div>
        </mat-step>

        <mat-step *ngIf="['LEXWARE'].includes(systemSelected)"
                  label="{{'optins.import-data.financial-data' | translate}}">
            <p class="text-justify pl-3 pr-3 mt-0">
                <span translate [translateParams]="fileType">optins.import-data.additional-information</span>
                <span class="settings-span" (click)="goToSettings()" translate>
                    optins.import-data.additional-information-system-settings
                </span>
            </p>
            <div class="row padding mt-6 mb-6 justify-content-center">
                <app-drag-and-drop-upload-file
                    #financialFile
                    (fileSelected)="handleFileSelect($event, 2)">
                </app-drag-and-drop-upload-file>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="row ml-1 float-right mt-6 action-buttons">
                        <button type="button" mat-button matStepperPrevious class="btn btn-primary mr-5"
                                translate>
                            system.back
                        </button>
                        <button type="button" mat-button class="btn btn-primary" (click)="importFiles()"
                                [disabled]="!allFilesAreSelected()"
                                translate>
                            optins.import-data.import-files
                        </button>
                    </div>
                </div>
            </div>
        </mat-step>

        <mat-step *ngIf="['LODAS', 'DATEV', 'EDLOHN', 'SAGE', 'ADDISON'].includes(systemSelected)"
                  label="{{'optins.import-data.wage-history-data' | translate}}">
            <p class="text-justify pl-3 pr-3 mt-0">
                <span translate [translateParams]="fileType">optins.import-data.additional-information</span>
                <span class="settings-span" (click)="goToSettings()" translate>
                    optins.import-data.additional-information-system-settings
                </span>
            </p>
            <div class="row padding mt-6 mb-6 justify-content-center">
                <app-drag-and-drop-upload-file
                    #wageHistoryFile
                    (fileSelected)="handleFileSelect($event, 3)">
                </app-drag-and-drop-upload-file>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="row ml-1 float-right mt-6 action-buttons">
                        <button type="button" mat-button matStepperPrevious class="btn btn-primary mr-5" translate>
                            system.back
                        </button>
                        <button type="button" mat-button class="btn btn-primary" (click)="importFiles()"
                                [disabled]="!allFilesAreSelected()" translate>
                            optins.import-data.import-files
                        </button>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>

<div *ngIf="importingData">
    <div class="row pl-4 pr-4 justify-content-center align-items-center">
        <div class="col upload-progress d-flex justify-content-center">
            <circle-progress
                [percent]="percentage"
                [startFromZero]="false"
                [showUnits]="true"
                [showSubtitle]="true"
                [radius]="80"
                [outerStrokeWidth]="8"
                [innerStrokeWidth]="4"
                [outerStrokeColor]="'#78C000'"
                titleFontSize="24"
                subtitleFontSize="14"
                titleFontWeight="600"
                [animation]="true"
                [animationDuration]="300"
                [subtitle]="subtitleFormat()"
            ></circle-progress>
        </div>
        <div class="col">
            <table class="table table-borderless table-vmiddle">
                <thead class="header-table">
                <tr>
                    <th scope="col" translate>common.status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of rows; let index = index">
                    <td class="text-start" *ngIf="!row.hidden">
                        <div class="d-flex align-items-center justify-content-between">
                            <span translate>{{row.label}}</span>
                            <ng-container
                                [ngTemplateOutlet]="
                                    statusIsDone(row.status) || statusIsTodoOrInProgress(row.status)
                                        ? stepInProgress
                                        : stepError
                                "
                                [ngTemplateOutletContext]="{ row: row }">
                            </ng-container>

                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-sm-12">
        <div class="row d-flex justify-content-center mt-4 action-buttons">
            <button type="button" mat-button class="btn btn-primary" (click)="closeDialog()"
                    [disabled]="!checkIfImportCompleted()" translate>
                system.complete
            </button>
        </div>
    </div>
</div>

<ng-template #stepInProgress let-row="row">
    <i class="material-icons-outlined text-success" *ngIf="statusIsDone(row.status)">
        check
    </i>
    <i class="material-icons-outlined text-warning" *ngIf="statusInProgress(row.status)"
        tooltipPos="up-right"
        tooltipSize="large">
        pending
    </i>
</ng-template>

<ng-template #stepError let-row="row">
    <span class="material-icons white text-danger"
        [finsteinTooltip]="row.tooltip"
        [enableTooltip]="row.tooltip"
        tooltipPos="up-right"
        tooltipSize="large"
        translate>
        error
    </span>
</ng-template>
