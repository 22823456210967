import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/abstractions/base-service';
import { EmployeeStatusEnum } from 'src/app/shared/enums/employee-status.enum';
import { FilterModel } from 'src/app/shared/models/filter.model';
import {
    EMPLOYEES_COLLECTION,
    IMPORT_EMPLOYEE_DATA_FUNCTION,
    MAX_SIZE_UPLOAD_OPTIN_IMPORTATION,
    OP_EQUALS,
    OP_IN,
    STATUS_DONE,
    STATUS_ERROR,
    STATUS_IN_PROGRESS,
    STATUS_TODO,
} from '../../../../app.constants';
import { BaseComponent } from '../../../../core/abstractions/base.component';
import {
    DragAndDropUploadFileComponent
} from '../../../../shared/components/drag-and-drop-upload-file/drag-and-drop-upload-file.component';
import { AccountingSystemsEnum } from '../../../../shared/enums/accounting-systems.enum';
import { formatBytes } from '../../../../utils/converter.utils';
import { checkFileExtension } from '../../../../utils/file.utils';
import { CompaniesService } from '../../../administration/company-leads/companies.service';
import { AddisonImportationStrategy } from '../importation-strageties/addison-importation-strategy';
import { DatevImportationStrategy } from '../importation-strageties/datev-importation-strategy';
import { EdlohnImportationStrategy } from '../importation-strageties/edlohn-importation-strategy';
import { LexwareImportationStrategy } from '../importation-strageties/lexware-importation-strategy';
import { LodasImportationStrategy } from '../importation-strageties/lodas-importation-strategy';
import { SageImportationStrategy } from '../importation-strageties/sage-importation-strategy';
import { ViewImportDataService } from 'src/app/core/components/view-import-data/view-import-data.service';

@Component({
    selector: 'app-choose-importation-file',
    templateUrl: './choose-importation-file.component.html',
    styleUrls: ['./choose-importation-file.component.scss'],
})
export class ChooseImportationFileComponent
    extends BaseComponent
    implements OnInit, OnDestroy
{
    @ViewChild('personalFile', { static: false })
    personalFile: DragAndDropUploadFileComponent;
    @ViewChild('financialFile', { static: false })
    financialFile: DragAndDropUploadFileComponent;
    @ViewChild('wageHistoryFile', { static: false })
    wageHistoryFile: DragAndDropUploadFileComponent;
    @ViewChild('singleFile', { static: false })
    singleFile: DragAndDropUploadFileComponent;
    subscriptions: Subscription = new Subscription();

    systemSelected;
    items: any = [];
    importedUsers: any = [];

    personalDataFile: any;
    financialDataFile: any;
    wageHistoryDataFile: any;

    lexwareType = 'XLSX';
    datevOrLodasType = 'TXT';

    currentAccountingSystem;

    percentage = 0;
    importingData = false;
    rows: any = [
        {
            property: 'processFile',
            label: 'optins.import-data.import-steps.processing-file',
            status: STATUS_TODO,
            minPercentage: 0,
            maxPercentage: 10,
            tooltip: '',
        },
        {
            property: 'extractEmployeeData',
            label: 'optins.import-data.import-steps.extract-employee-data',
            status: STATUS_TODO,
            minPercentage: 10,
            maxPercentage: 42,
            tooltip: '',
        },
        {
            property: 'normalizeData',
            label: 'optins.import-data.import-steps.normalize-data',
            status: STATUS_TODO,
            minPercentage: 42,
            maxPercentage: 60,
            tooltip: '',
            hidden: true,
        },
        {
            property: 'importEmployeeData',
            label: 'optins.import-data.import-steps.importing-employee-data',
            status: STATUS_TODO,
            minPercentage: 60,
            maxPercentage: 100,
            tooltip: '',
        },
    ];
    IMPORT_EMPLOYEE_DATA_INDEX = 3;
    employeesProcessed = 0;

    constructor(
        private viewImportDataService: ViewImportDataService,
        private dialog: MatDialogRef<ChooseImportationFileComponent>,
        private lexwareImportationStrategy: LexwareImportationStrategy,
        private datevImportationStrategy: DatevImportationStrategy,
        private addisonImportationStrategy: AddisonImportationStrategy,
        private lodasImportationStrategy: LodasImportationStrategy,
        private edlohnImportationStrategy: EdlohnImportationStrategy,
        private sageImportationStrategy: SageImportationStrategy,
        private companyService: CompaniesService,
        private service: BaseService<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
        this.items = data.items;
        this.systemSelected = data.systemSelected.toUpperCase();
    }

    ngOnInit() {
        super.ngOnInit();
        this.companyService
            .getCurrentWageAccountingSystem()
            .subscribe((data) => {
                this.currentAccountingSystem = data;
            });
        this.importationStrategy.onProgress = new Subject<number>();
        this.importationStrategy.onProgress.subscribe((percentage) => {
            this.percentage = percentage * 0.6;
            this.handleRowStatus();
        });
    }

    ngOnDestroy() {
        this.subscriptions?.unsubscribe();
    }

    goToSettings() {
        this.dialog.close();
        this.navigate(['/settings/optins-import']);
    }

    async importFiles() {
        try {
            this.importingData = true;
            const result = await this.importationStrategy.import(
                this.personalDataFile,
                this.financialDataFile ?? this.wageHistoryDataFile,
                this.items
            );
            this.importationStrategy.onProgress
                .pipe(
                    finalize(() => {
                        this.importedUsers = result;
                        this.openViewImportData();
                    })
                )
                .subscribe();
        } catch (error) {
            this.importingData = false;
            this.notification.error(
                'messages.unknown-error-when-importing-file'
            );
            console.error(error);
            const message = error.error ? error.error.message : error.message;
            if (message) {
                this.fireLogginService.sendErrorLog(
                    `An error occurred while importing optin file with system: ${this.systemSelected}, details: ${message}`
                );
            } else {
                this.fireLogginService.sendErrorLog(
                    `An error occurred while importing optin file with system: ${
                        this.systemSelected
                    }, details: ${JSON.stringify(error)}`
                );
            }
        }
    }

    openViewImportData() {
        this.employeesProcessed = 0;
        this.viewImportDataService
            .open(this.importedUsers)
            .pipe(
                switchMap((employees) => {
                    if (employees) {
                        this.importedUsers = employees;
                        return this.viewImportDataService.importOptinData(employees);
                    } else {
                        return of(false);
                    }
                })
            )
            .subscribe((res) => {
                this.percentage = 100;
                this.employeesProcessed = this.importedUsers.length;
                if (res) {
                    this.handleRowStatus('importEmployeeData', !!res.rejected);
                } else {
                    this.closeDialog();
                }
            });

        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_IN,
                value: [
                    EmployeeStatusEnum.CONSULTATION_APPROVAL_PENDING,
                    EmployeeStatusEnum.NOT_ELIGIBLE_FOR_OPTIMIZATION,
                    EmployeeStatusEnum.DATA_EXPORT_INCOMPLETE,
                    EmployeeStatusEnum.DATA_EXPORT_PENDING,
                    EmployeeStatusEnum.DATA_MISSING,
                ],
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: this.userService.companyId,
            },
        ];
        this.subscriptions.add(
            this.service
                .search(EMPLOYEES_COLLECTION, filter)
                .subscribe((employees) =>
                    this.setEmployeesProcessedFlag(employees)
                )
        );
    }

    private setEmployeesProcessedFlag(employees: any) {
        const employeesProcessed = employees.filter((employee) => {
            const employeeWithSameId = this.importedUsers.find(
                (e) => e.id === employee.id
            );
            return (
                employeeWithSameId &&
                employeeWithSameId.lastModifiedAt.getTime() !==
                    employee.lastModifiedAt.toDate().getTime()
            );
        });
        if (this.percentage < 100) {
            this.percentage =
                (employeesProcessed.length / this.importedUsers.length) * 40 +
                60;
            this.employeesProcessed = employeesProcessed.length;
        }
    }

    handleFileSelect(file, type: 1 | 2 | 3) {
        let validatedFile;
        if (this.validateFile(file)) {
            validatedFile = file;
        }

        if (type === 1) {
            this.personalDataFile = validatedFile;
        } else if (type === 2) {
            this.financialDataFile = validatedFile;
        } else {
            this.wageHistoryDataFile = validatedFile;
        }
    }

    allFilesAreSelected() {
        if (this.systemSelected === 'LEXWARE') {
            return this.personalDataFile && this.financialDataFile;
        }
        if (
            this.systemSelected === 'LODAS' ||
            this.systemSelected === 'DATEV'
        ) {
            return this.personalDataFile && this.wageHistoryDataFile;
        }
        return true;
    }

    private validateFile(file: File) {
        try {
            if (!checkFileExtension(file, ['.xlsx', '.txt', '.csv'])) {
                this.notification.error(
                    'messages.invalid-file-type-optin-importation'
                );
                return false;
            }
            if (file.size > MAX_SIZE_UPLOAD_OPTIN_IMPORTATION) {
                this.notification.error('messages.file-size-exceeded', {
                    size: formatBytes(MAX_SIZE_UPLOAD_OPTIN_IMPORTATION),
                });
                return false;
            }
            return true;
        } catch (err) {
            return false;
        }
    }

    get fileType() {
        let fileType;
        if (this.systemSelected === AccountingSystemsEnum.LEXWARE) {
            fileType = this.lexwareType;
        } else {
            fileType = this.datevOrLodasType;
        }
        return { fileType };
    }

    get importationStrategy() {
        if (this.systemSelected === AccountingSystemsEnum.LEXWARE) {
            return this.lexwareImportationStrategy;
        }
        if (this.systemSelected === AccountingSystemsEnum.DATEV) {
            return this.datevImportationStrategy;
        }
        if (this.systemSelected === AccountingSystemsEnum.LODAS) {
            return this.lodasImportationStrategy;
        }
        if (this.systemSelected === AccountingSystemsEnum.EDLOHN) {
            return this.edlohnImportationStrategy;
        }
        if (this.systemSelected === AccountingSystemsEnum.SAGE) {
            return this.sageImportationStrategy;
        }
        if (this.systemSelected === AccountingSystemsEnum.ADDISON) {
            return this.addisonImportationStrategy;
        }
    }

    closeDialog() {
        this.dialog.close();
        this.importedUsers = [];
        this.percentage = 0;
        this.importationStrategy.onProgress.complete();
        this.importationStrategy.progress = 0;
    }

    checkIfImportCompleted() {
        return (
            (this.importedUsers.length && this.employeesProcessed === this.importedUsers.length) ||
            this.rows.find((e) => e.status === STATUS_ERROR) ||
            this.percentage >
                this.rows[this.IMPORT_EMPLOYEE_DATA_INDEX].minPercentage
        );
    }

    canDeactivate(): boolean {
        return !this.importingData;
    }

    handleRowStatus(property?: string, hasError?: any) {
        this.rows.forEach((row) => {
            if (
                this.percentage >= row.minPercentage &&
                this.percentage < row.maxPercentage
            ) {
                row.status = STATUS_IN_PROGRESS;
            }
            if (this.percentage >= row.maxPercentage) {
                row.status = STATUS_DONE;
            }
            if (row.property === property && hasError) {
                row.status = STATUS_ERROR;
                row.tooltip = 'optins.import-data.employee-import-error';
            }
        });
    }

    statusIsDone(status) {
        return status === STATUS_DONE;
    }

    statusInProgress(status) {
        return status === STATUS_IN_PROGRESS;
    }

    statusIsTodoOrInProgress(status) {
        return status === STATUS_TODO || status === STATUS_IN_PROGRESS;
    }

    subtitleFormat(): string {
        return `${this.employeesProcessed}/${this.importedUsers?.length || 0}`;
    }
}
