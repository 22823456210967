import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { Papa } from 'ngx-papaparse';
import * as XLSX from 'xlsx';
import {
    COMPANIES_COLLECTION,
    ENCODING_READER_CSV_FILE,
    IMPORT_SETTINGS_COLLECTION,
    OPTINS_IMPORT_SETTINGS_ID
} from '../../../../app.constants';
import { UserService } from '../../../../core/services/user.service';
import { OptinsImportFileTypeEnum } from '../../../../shared/enums/optins-import-file-type.enum';
import { checkFileExtension } from '../../../../utils/file.utils';
import { AbstractImportationStrategy } from './abstract-importation-strategy';
import { FirestoreService } from 'src/app/core/services/firestore.service';

@Injectable()
export class LexwareImportationStrategy extends AbstractImportationStrategy {

    optins = [];

    public progressData = {
        startProcessFile: {
            ratio: 2.5
        },
        finishProcessFile: {
            ratio: 2.5
        },
        extractPersonalData: {
            ratio: 30
        },
        extractFinancialData: {
            ratio: 15
        },
        normalizeData: {
            ratio: 30
        }
    };

    constructor(private papa: Papa,
                private userService: UserService,
                private firestoreService: FirestoreService,
    ) {
        super();
    }

    async import(personalFile: File, financialFile: File, optins): Promise<any> {
        try {
            this.optins = optins;
            const fullPersonalDataList = await this.processFile(personalFile, OptinsImportFileTypeEnum.PERSONAL_DATA)
                .then((data) => data);
            const fullFinancialDataList = await this.processFile(financialFile, OptinsImportFileTypeEnum.FINANCIAL_DATA)
                .then((data) => data);
            if (this.userService.isDebugImportFileMode) {
                await this.saveFilesInFirestoreForFutureDebug(
                    this.firestoreService,
                    personalFile,
                    this.userService.companyId
                );
                await this.saveFilesInFirestoreForFutureDebug(
                    this.firestoreService,
                    financialFile,
                    this.userService.companyId
                );
            }
            return await this.extractOptinsFromImportedLists(fullPersonalDataList, fullFinancialDataList).then((data) => data);
        } catch (e) {
            throw new Error(e);
        }
    }

    private processFile(file: File, type: OptinsImportFileTypeEnum) {
        this.emitProgress(this.progressData.startProcessFile.ratio);
        if (checkFileExtension(file, ['.csv'])) {
            return this.processCSV(file); // At the moment, the app will not allow CSV files
        } else {
            return this.processXLSX(file, type);
        }
    }

    private processCSV(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsText(file, ENCODING_READER_CSV_FILE);
                reader.onerror = (err) => {
                    reject(err);
                };
                reader.onload = (event: any) => {
                    this.papa.parse(event.target.result, {
                        skipEmptyLines: true,
                        header: true,
                        encoding: ENCODING_READER_CSV_FILE,
                        complete: async (results) => {
                            this.emitProgress(this.progressData.finishProcessFile.ratio);
                            resolve(results.data);
                        }
                    });
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private processXLSX(file: File, type: OptinsImportFileTypeEnum) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onerror = (err) => {
                    reject(err);
                };
                fileReader.onload = (e) => {
                    const arrayBuffer: any = fileReader.result;
                    const data = new Uint8Array(arrayBuffer);
                    const arr = [];
                    for (let i = 0; i !== data.length; ++i) {
                        arr[i] = String.fromCharCode(data[i]);
                    }
                    const bstr = arr.join('');
                    const workbook = XLSX.read(bstr, {type: 'binary', cellDates: true});
                    if (type === OptinsImportFileTypeEnum.PERSONAL_DATA) {
                        const firstSheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[firstSheetName];
                        this.emitProgress(this.progressData.finishProcessFile.ratio);
                        resolve(XLSX.utils.sheet_to_json(worksheet, {raw: true}));
                    } else {
                        const result = {};
                        workbook.SheetNames.forEach((sheetName) => {
                            const worksheet = workbook.Sheets[sheetName];
                            result[sheetName] = XLSX.utils.sheet_to_json(worksheet, {raw: true});
                        });
                        this.emitProgress(this.progressData.finishProcessFile.ratio);
                        resolve(result);
                    }
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private extractOptinsFromImportedLists(personalData: any, financialData: any) {
        return new Promise((resolve, reject) => {
            this.service.getById(
                OPTINS_IMPORT_SETTINGS_ID,
                `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${IMPORT_SETTINGS_COLLECTION}`
            ).subscribe({
                next: (fields) => {
                    try {
                        this.emitProgress(this.progressData.extractPersonalData.ratio);

                        const optinsPersonalData = this.extractPersonalDataByOptins(
                            personalData,
                            fields.personalData,
                            this.optins
                        );
                        // Add the registration to the financial data fields to help the check later.
                        fields.financialData.registration = {
                            ...fields.personalData.registration
                        };
                        const optinsFinancialData = this.extractFinancialDataByImportedUsers(
                            financialData.LohnAbrechnung,
                            fields.financialData,
                            optinsPersonalData
                        );
                        const optinsWageData = this.extractFinancialDataByImportedUsers(
                            financialData.LohnDaten,
                            fields.financialData,
                            optinsPersonalData
                        );
                        const result = this.normalizeData(this.joinAllData(optinsPersonalData, optinsFinancialData, optinsWageData));
                        this.emitProgress(this.progressData.normalizeData.ratio);
                        resolve(result);
                    } catch (e) {
                        reject(e);
                    }
                },
                error: (error => {
                    reject(error);
                })
            });
        });
    }

    private joinAllData(personalData, payrollData, wageData) {
        const result = [];
        if (personalData.length === 0) {
            this.emitProgress(this.progressData.normalizeData.ratio);
        } else {
            const progressJumps = (this.progressData.normalizeData.ratio / personalData.length);
            for (let optIn of personalData) {
                for (const payroll of payrollData) {
                    if (payroll.registration === optIn.registration) {
                        optIn = {...optIn, ...payroll};
                    }
                }
                for (const wage of wageData) {
                    if (wage.registration === optIn.registration) {
                        if (!optIn.wageData) {
                            optIn.wageData = [];
                        }
                        delete wage.registration;
                        optIn.wageData.push(wage);
                    }
                }
                this.emitProgress(progressJumps);
                result.push(optIn);
            }
        }
        return result;
    }

    private extractFinancialDataByImportedUsers(financialData: any[], fields, importedUsers: any[]) {
        const result = [];
        if (financialData.length === 0) {
            this.emitProgress(this.progressData.extractFinancialData.ratio);
        } else {
            const progressJumps = (this.progressData.extractFinancialData.ratio / financialData.length);
            for (const importedFinancialData of financialData) {
                const data = this.buildObject(importedFinancialData, fields);
                if (this.matchOptinFinancialData(data, importedUsers)) {
                    result.push(data);
                }
                this.emitProgress(progressJumps);
            }
        }
        return result;
    }

    private extractPersonalDataByOptins(personalData: any[], fields, optins: any[]) {
        const result = [];
        if (personalData.length === 0) {
            this.emitProgress(this.progressData.extractPersonalData.ratio);
        } else {
            const progressJumps = (this.progressData.extractPersonalData.ratio / personalData.length);
            for (const importedUser of personalData) {
                const user = this.buildObject(importedUser, fields);
                const foundOptin = this.findOptinUser(user, optins);
                if (foundOptin) {
                    user.lastModifiedAt = foundOptin.lastModifiedAt;
                    result.push(user);
                }
                this.emitProgress(progressJumps);
            }
        }
        return result;
    }

    private normalizeData(items: any[]) {
        for (const item of items) {
            item.religion = ![null, undefined, '--', '- -', 'vd', '', ' '].includes(item.religion);
            if (isNil(item.taxFactor)) {
                item.taxFactor = 1;
            }
            item.gender = this.getGender(item.gender);
            if (item.regularDailyWorkingHours && item.workingDaysPerWeek) {
                item.weeklyWorkingHours =
                item.regularDailyWorkingHours * item.workingDaysPerWeek;
            }

            if (item.privateHealthInsurance) {
                item.privateHealthInsurance = ['ja', 'yes'].includes(item.privateHealthInsurance.trim().toLowerCase());
            }

            item.type = 'lexware';
            item.registration = item.registration?.toString();
            delete item.grossSalary;
        }
        return items;
    }

}
