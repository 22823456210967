import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as firestore from 'firebase/firestore';
import lodash from 'lodash';
import { Observable } from 'rxjs';
import { TableColumnModel } from 'src/app/shared/models/table.model';
import {
    ACTION_CREATE,
    ITEMS_PER_PAGE,
    STATUS_ACTIVE,
    STATUS_APPROVED,
    STATUS_DATA_COMPLETED,
    STATUS_DATA_MISSING,
    STATUS_PARTIALLY_APPROVED,
    STATUS_PENDENT,
} from '../../../app.constants';
import { InputMaskService } from '../../../core/services/input-mask.service';
import { StorageService } from '../../../core/services/storage.service';
import { UserService } from '../../../core/services/user.service';
import { formatDate } from '../../../utils/converter.utils';
import { EmployeeStatusEnum } from '../../enums/employee-status.enum';

@Component({
    selector: 'app-table-consulting-progress',
    templateUrl: './table-consulting-progress.component.html',
    styleUrls: ['./table-consulting-progress.component.scss']
})
export class TableConsultingProgressComponent {

    public readonly STATUS_PENDENT = STATUS_PENDENT;
    public readonly WAITING_CONTRACT_SIGNATURE = EmployeeStatusEnum.WAITING_CONTRACT_SIGNATURE;

    public statusList = {
        ACTIVE: 'status.active',
        CONTRACT_EXPIRED: 'status.contract-expired',
        CONTRACT_CANCELED: 'status.contract-canceled',
        WAITING_CONTRACT_SIGNATURE: 'status.waiting-contract-signature'
    };

    @Input() items: Observable<any>[];
    @Input() columns: any[];
    @Input() pageable = true;
    @Input() itemsSelected: any[] = [];
    @Input() disableCheckConditionFunction: any;
    @Input() bordered = false;
    @Input() currentPage = 0;
    @Input() totalItems = 0;
    @Input() confirmationDialogSwitch = true;
    @Input() textConfirmationEnableDialogSwitch = 'operations.enable.confirmation';
    @Input() textConfirmationDisableDialogSwitch = 'operations.disable.confirmation';
    @Input() permissionToApprove: string;
    @Input() verifyIsChecked: boolean;

    @Output() approvalDetails = new EventEmitter();
    @Output() edit = new EventEmitter();
    @Output() download = new EventEmitter();
    @Output() changeStatus = new EventEmitter();
    @Output() markCopEmployeeContractAsSigned = new EventEmitter();
    @Output() resendEmployeeToCop = new EventEmitter();
    @Output() check = new EventEmitter();
    @Output() changeSort = new EventEmitter();
    @Output() paginationChange = new EventEmitter();

    constructor(protected userService: UserService,
                public dialog: MatDialog,
                protected translateService: TranslateService,
                protected inputMask: InputMaskService,
                protected storage: StorageService) {
    }

    get pageSize() {
        return ITEMS_PER_PAGE;
    }

    onEdit(item) {
        this.edit.emit(item);
    }

    onPaginationChanged(newPage: number) {
        this.paginationChange.emit(newPage);
    }

    onMarkCopEmployeeContractAsSigned(item) {
        this.markCopEmployeeContractAsSigned.emit(item);
    }

    onResendEmployeeToCop(item) {
        this.resendEmployeeToCop.emit(item);
    }

    onApprovalDetails(item) {
        this.approvalDetails.emit(item);
    }

    onChangeSort({active, direction}) {
        this.changeSort.emit({column: active, sort: direction});
    }

    hasPendentApprovalForThisProperty(item, column) {
        return item.pendentApproval && item.pendentApproval.backup && item.pendentApproval.action !== ACTION_CREATE &&
            Object.keys(item.pendentApproval.backup).includes(column.property);
    }

    hasEditAction() {
        return this.edit.observers.length > 0;
    }

    hasAnyAction(item: any) {
        return [
            EmployeeStatusEnum.CONTRACT_CANCELED,
            EmployeeStatusEnum.CONTRACT_EXPIRED,
            EmployeeStatusEnum.WAITING_CONTRACT_SIGNATURE
        ].includes(item.status);
    }

    hasActiveColumn(column) {
        return column?.property === 'active';
    }

    onClickValue(item, column) {
        if (column.onClick) {
            column.onClick(item);
        }
    }

    getColumnValue(item, column) {
        const value = lodash.get(item, column.property);
        if (column.property === 'status') {
            const statusKey = this.statusList[(value.replace(/ /g, '_'))];
            return (statusKey ? this.translateService
                .instant(statusKey) : value).toUpperCase();
        } else if (value instanceof Date || value instanceof firestore.Timestamp) {
            return formatDate(value, this.inputMask.dateMaskFormat);
        } else if (column.fixedValue) {
            return column.getTranslatedText ? column.getTranslatedText(item) : column.fixedValue;
        } else if (column.propertyDe && column.propertyEn) {
            if (this.getPropertyLanguage() === 'de') {
                return lodash.get(item, column.propertyDe);
            } else {
                return lodash.get(item, column.propertyEn);
            }
        } else {
            if (typeof value === 'boolean') {
                return value ? this.translate('system.yes') : this.translate('system.no');
            }
            return column.getTranslatedText ? column.getTranslatedText(item) : value;
        }
    }

    getCssClass(column: TableColumnModel, item) {
        let className = '';
        if (column.onClick) {
            className += 'link ';
        }
        if (column.property === 'status') {

            if (item.status === STATUS_ACTIVE || item.status === STATUS_APPROVED || item.status === STATUS_DATA_COMPLETED) {
                className += 'badge badge-success mt-3 ';
            }
            if (item.status === STATUS_PARTIALLY_APPROVED || item.status === EmployeeStatusEnum.READY_FOR_CONSULTATION_PROCESS) {
                className += 'badge badge-primary mt-3 ';
            }
            if (item.status === STATUS_DATA_MISSING || item.status === EmployeeStatusEnum.CONTRACT_EXPIRED ||
                item.status === EmployeeStatusEnum.CONTRACT_CANCELED) {
                className += 'badge badge-danger mt-3 ';
            }
            if (item.status === STATUS_PENDENT || item.status === EmployeeStatusEnum.WAITING_CONTRACT_SIGNATURE) {
                className += 'badge badge-warning mt-3 ';
            }
        }
        /* Each implementation defines the rule of applying the class to the field; */
        if (column.getClassName) {
            className = column.getClassName(item);
        }

        return className.trim();
    }

    isChecked(item) {
        return !!this.itemsSelected?.find((it) => it.id === item.id);
    }

    translate(key: string) {
        return this.translateService.instant(key);
    }

    hasPendentApproval(item) {
        return !this.userService.loggedUser.finsteinUser && item && item.pendentApproval;
    }

    getPropertyLanguage() {
        return this.translateService.currentLang;
    }

}
