import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
    APPROVE_OPTIN_FUNCTION,
    EMPLOYEES_COLLECTION,
    OP_EQUALS,
    OP_IN,
    STATUS_DONE,
    STATUS_ERROR,
    STATUS_IN_PROGRESS,
    STATUS_TODO,
    UPDATE_STATUS_DESCRIPTION_OPTIN_FUNCTION,
} from 'src/app/app.constants';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { EmployeeStatusEnum } from 'src/app/shared/enums/employee-status.enum';
import { FilterModel } from 'src/app/shared/models/filter.model';
import { BaseService } from '../../../../../core/abstractions/base-service';

@Component({
    selector: 'app-pendent-progress-modal',
    templateUrl: './pendent-progress-modal.component.html',
    styleUrls: ['./pendent-progress-modal.component.scss'],
})
export class PendentProgressModalComponent
    extends BaseComponent
    implements OnInit, OnDestroy
{
    percentage = 0;
    protected subscriptions = new Subscription();

    row = {
        property: 'processedOptin',
        label: 'optins.processed-employees',
        status: STATUS_TODO,
        tooltip: '',
    };

    selectedOptins = [];
    selectedDescription: string;
    employeesProcessed = 0;

    processFinished = false;

    constructor(
        private service: BaseService<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectedOptins: Array<{ id: string }>;
            selectedDescription: string;
        },
        private dialog: MatDialogRef<PendentProgressModalComponent>
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.data) {
            this.selectedOptins = this.data.selectedOptins.map((e) => e.id);
            this.selectedDescription = this.data.selectedDescription;

            const filter = new FilterModel();
            filter.clauses = [
                {
                    fieldPath: 'status',
                    opStr: OP_IN,
                    value: [
                        EmployeeStatusEnum.VERIFICATION_PENDING,
                        EmployeeStatusEnum.DATA_EXPORT_PENDING,
                    ],
                },
                {
                    fieldPath: 'companyId',
                    opStr: OP_EQUALS,
                    value: this.userService.companyId,
                },
            ];
            this.subscriptions.add(
                this.service
                    .search(EMPLOYEES_COLLECTION, filter)
                    .subscribe((employees) =>
                        this.setEmployeesProcessedFlag(employees)
                    )
            );
            this.confirmOptinEmailStatus();
        }
    }

    private setEmployeesProcessedFlag(employees: any) {
        const employeesWithNewStatus = employees.filter(
            (employee) =>
                this.selectedOptins.includes(employee.id) &&
                (employee.status === EmployeeStatusEnum.DATA_EXPORT_PENDING)
        );
        if (this.percentage < 100) {
            this.percentage =
                (employeesWithNewStatus.length / this.selectedOptins.length) *
                100;
            this.employeesProcessed = employeesWithNewStatus.length;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    statusIsDone(status) {
        return status === STATUS_DONE;
    }

    statusInProgress(status) {
        return status === STATUS_IN_PROGRESS;
    }

    statusIsTodoOrInProgress(status) {
        return status === STATUS_TODO || status === STATUS_IN_PROGRESS;
    }

    confirmOptinEmailStatus() {
        let functionName = UPDATE_STATUS_DESCRIPTION_OPTIN_FUNCTION;

        if (this.selectedDescription === 'EMPLOYEE_AND_EMAIL_KNOW') {
            functionName = APPROVE_OPTIN_FUNCTION;
        }
        this.subscriptions.add(
            this.service
                .callFunction(
                    {
                        optins: this.selectedOptins,
                        statusDescription: this.selectedDescription,
                    },
                    functionName,
                    {
                        type: 'callable',
                        httpMethod: 'POST',
                    }
                )
                .subscribe((res) => {
                    this.percentage = 100;

                    if (res && res.details) {
                        this.employeesProcessed =
                            res.details.rejected + res.details.fulfilled;
                        if (res.details.rejected) {
                            this.row.tooltip = this.translateService.instant(
                                'optins.not-processed-employees',
                                {
                                    errorAmount: res.rejected,
                                    total: this.employeesProcessed,
                                }
                            );
                            this.row.status = STATUS_ERROR;
                        } else {
                            setTimeout(() => {
                                this.dialog.close();
                            }, 1000);
                        }
                    } else {
                        this.row.status = STATUS_ERROR;
                    }
                })
        );
    }

    dialogClose() {
        this.dialog.close();
    }

    subtitleFormat(): string {
        return `${this.employeesProcessed}/${this.selectedOptins.length || 0}`;
    }
}
