/* src/app/modules/company/optins/required-settings/required-settings.component.scss */
.task-option {
  font-size: 18px !important;
}
.task-done .icon {
  color: green;
}
.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.close.mat-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}
/*# sourceMappingURL=required-settings.component-5NVYM36X.css.map */
