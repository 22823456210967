<finstein-base-modal [showActions]="false">
  <div class="modal-container" content>
      <div class="page-title text-center">
          <span >
              {{ "optins.import-data.required-settings.title" | translate }}
              <span class="material-icons"> warning </span>
          </span>
      </div>
      <div class="row content-center mb-5">
          <small translate
              >optins.import-data.required-settings.description</small
          >
      </div>
        <ul class="pl-2">
            <li *ngFor="let task of tasks" class="content-center">
                <label
                    [for]="task.name + '-settings'"
                    *ngIf="!task.completed"
                    class="d-flex align-items-center task-option task-settings"
                >
                    {{ task.name }}
                    <span
                        *ngIf="!task.completed"
                        class="material-icons ml-3"
                        (click)="changePage(task.link, task.params)"
                        finsteinTooltip="optins.import-data.configure-now"
                    >
                        settings
                    </span>
                </label>
                <label
                    [for]="task.name + '-done'"
                    *ngIf="task.completed"
                    class="d-flex align-items-center task-option task-done"
                >
                    {{ task.name }}
                    <span class="material-icons icon ml-3"> done </span>
                </label>
            </li>
        </ul>
    </div>
</finstein-base-modal>
