/* src/app/modules/company/optins/list/optins-list.component.scss */
.select-file-input {
  display: none;
}
.icon-optins-importation-settings {
  margin-top: 20px;
}
.invite-link-dropdown-container {
  position: absolute;
  right: 0;
  margin-top: 16px;
}
.invite-link-group input:disabled {
  background-color: #f6f6f6;
}
.invite-link-dropdown-title {
  color: #275fa1;
  font-weight: 600;
}
.renew {
  color: #5b6c80;
  cursor: pointer;
}
.copy .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.copy .input-group-text i {
  cursor: pointer;
  color: #5b6c80;
}
.copy .input-group-text:hover {
  background-color: #f3f3f3;
}
.dropdown-menu-arrow {
  top: -17px;
  left: 95%;
  height: 0;
  position: relative;
}
.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 9px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
  top: -9px;
}
.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -10px;
  border-bottom-color: #cecbcb;
}
.dropdown-menu-arrow:before {
  bottom: -18px;
  right: -10px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}
.rounded {
  border-radius: 20px !important;
}
.nav-tabs {
  border-color: transparent !important;
  background: #0760ef !important;
}
.nav-tabs .nav-link {
  color: white;
}
.nav-tabs .nav-link .tab-info-icon {
  font-size: 30px !important;
}
::ng-deep .release-tab-popover {
  max-width: 50vw !important;
  max-height: 85vh;
  overflow-y: scroll;
  border: 2px solid #125EED;
  top: 10% !important;
}
::ng-deep .release-tab-popover .arrow {
  display: none !important;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-header {
  display: flex;
  background-color: transparent;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-header p {
  font-weight: 600;
  color: #394551;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content {
  display: flex;
  padding: 1rem 4rem 1rem 1rem;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-content-icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: #F5F5FA;
  display: flex;
  justify-content: center;
  align-items: center;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-text {
  font-size: 14px;
  margin-bottom: 0.5rem;
  opacity: 0.65;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-text a {
  color: #125EED;
  text-decoration: underline;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 1rem;
  max-width: 35%;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-text.custom-text a {
  max-width: unset;
  margin-left: 0;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-content .title {
  font-weight: 600;
  color: #394551;
  margin-bottom: 0;
  margin-right: 0.5rem;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-content .popover-email-text .email-and-password {
  display: flex;
}
::ng-deep .release-tab-popover .cop-popover-container .popover-content .email-content .popover-email-text .email-and-password p {
  color: #125EED;
}
::ng-deep .not-eligible-error-popover,
::ng-deep .approved-error-popover {
  max-width: 550px;
  border: 2px solid #125EED;
}
::ng-deep .not-eligible-error-popover .arrow:before,
::ng-deep .approved-error-popover .arrow:before {
  top: -2px;
  left: -2px;
  border-width: 0.7rem 0.7rem 0.7rem 0px;
  border-right-color: #125EED;
}
::ng-deep .not-eligible-error-popover .arrow:after,
::ng-deep .approved-error-popover .arrow:after {
  border-width: 0.6rem 0.6rem 0.6rem 0px;
}
::ng-deep .not-eligible-error-popover .popover-body,
::ng-deep .approved-error-popover .popover-body {
  padding: 1rem;
}
::ng-deep .not-eligible-error-popover .popover-body .not-eligible-popover-container,
::ng-deep .approved-error-popover .popover-body .not-eligible-popover-container {
  display: flex;
}
::ng-deep .not-eligible-error-popover .popover-body .not-eligible-popover-container .popover-header,
::ng-deep .approved-error-popover .popover-body .not-eligible-popover-container .popover-header {
  padding-top: 0.3rem;
  background-color: white;
  border-bottom: none;
}
::ng-deep .not-eligible-error-popover .popover-body .not-eligible-popover-container .popover-content .bold,
::ng-deep .approved-error-popover .popover-body .not-eligible-popover-container .popover-content .bold {
  font-weight: bold;
}
.nav-link.active {
  background: white;
  color: #0760ef !important;
}
.nav-link.active .tab-info-icon {
  color: #375884;
}
.nav-tabs .nav-link i,
.nav-tabs .nav-link span {
  text-align: center !important;
}
.margin-content {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.btn-action {
  height: 30px;
  width: 30px;
  background-color: #5B6D80;
}
.btn-action:hover {
  background-color: #0760ef;
}
.add-optin-button {
  margin-top: 16px;
}
.row-buttons {
  margin-right: 10px;
}
.info-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-container .popover-arrow {
  opacity: 0;
}
.popover-arrow {
  right: 0;
  top: 0;
  margin-top: 0.5rem;
  width: 0.5rem;
  height: 1rem;
  position: relative;
  z-index: 9999;
}
.popover-arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #125EED;
}
.popover-arrow:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  right: 3px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
@media (max-height: 900px) {
  ::ng-deep .release-tab-popover {
    max-width: 60% !important;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  ::ng-deep .release-tab-popover {
    max-width: 60% !important;
    top: unset !important;
  }
  ::ng-deep .release-tab-popover .cop-popover-container .popover-header p {
    margin-bottom: 0;
  }
  ::ng-deep .release-tab-popover .cop-popover-container .popover-content {
    padding: 1rem 3rem 0.5rem 1rem;
  }
}
/*# sourceMappingURL=optins-list.component-TSA4PRHX.css.map */
