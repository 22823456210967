/* src/app/shared/components/table-consulting-progress/table-consulting-progress.component.scss */
.cursor-default {
  cursor: default;
}
.header-table th {
  font-weight: 500;
  text-transform: uppercase;
  color: #5B6D80;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.5rem 15px;
  vertical-align: middle;
}
.check-all {
  top: -14px;
}
.th-checkbox {
  width: 50px;
}
.table-pagination {
  width: 100%;
}
.report-icon,
.row-warning-icon {
  cursor: default;
  height: 0;
  float: left;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  -webkit-animation: fadein 0.5s;
  -o-animation: fadein 0.5s;
}
.row-warning-icon {
  color: rgba(255, 193, 7, 0.7490196078);
}
.report-icon {
  color: #dc3545;
}
tr:nth-child(even) {
  background-color: #f0f7ff;
}
td,
th {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  color: #8C969F;
}
.table td {
  padding: 0.2rem 15px 0.2rem 15px;
}
.button-new-company {
  border-radius: 40px 40px 40px 40px;
  color: #fff;
  float: right;
  display: inline-flex;
  background: rgb(7, 96, 239);
  background:
    linear-gradient(
      0deg,
      rgb(7, 96, 239) 0%,
      rgb(118, 244, 254) 100%);
  border: none;
  margin-bottom: 30px;
}
.button-new-company:hover {
  -webkit-box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 12px -4px rgba(0, 0, 0, 0.75);
}
.btn-action {
  margin-right: 5px;
}
.btn-action:last-child {
  margin-right: 0;
}
td {
  max-width: 25vw;
}
tr {
  height: 48px;
}
.overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  text-align: center;
  margin: auto;
  display: inline-block;
}
.icon-text {
  color: #0760ef;
  width: 25px;
  height: 25px;
}
.table ::ng-deep .ng2-tag-input,
.table ::ng-deep .ng2-tag-input__text-input {
  border: none;
  background: transparent;
}
.table ::ng-deep .ng2-tag-input__text-input {
  width: 7.3vw;
}
.table ::ng-deep .ng2-tag-input.ng2-tag-input--focused {
  border: none;
}
.table ::ng-deep .tag__text {
  color: #8C969F;
}
.table ::ng-deep delete-icon path {
  fill: #8C969F !important;
}
.table ::ng-deep .tag-wrapper {
  margin-top: -2px;
}
.table ::ng-deep tag:not(.readonly):not(.tag--editing):active {
  background: #e2e2e2;
}
.table ::ng-deep tag {
  border-radius: 4px;
}
.table ::ng-deep .mat-sort-header-container {
  justify-content: center;
}
.table ::ng-deep .mat-sort-header-arrow {
  position: absolute;
  left: 90%;
}
.icon-edit-input {
  cursor: pointer;
  margin-left: -39px;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.icon-edit-input i {
  margin-top: 2px;
  margin-left: 5px;
  color: #748AA2;
  font-size: 21px;
}
.table-select ::ng-deep .ng-select-container {
  height: 40px;
}
.active {
  font-weight: bold;
  color: #28a745;
}
.approved {
  font-weight: bold;
  color: #28a745;
}
.data-completed {
  font-weight: bold;
  color: #0760ef;
}
.data-missing {
  font-weight: bold;
  color: rgba(255, 193, 7, 0.7490196078);
}
.rejected {
  font-weight: bold;
  color: #dc3545;
}
.inactive {
  font-weight: bold;
  color: #6c757d;
}
.partially-approved {
  font-weight: bold;
  color: rgba(255, 193, 7, 0.7490196078);
}
.pendent {
  font-weight: bold;
  color: rgba(255, 193, 7, 0.7490196078);
}
.todo {
  font-weight: bold;
  color: rgba(255, 193, 7, 0.7490196078);
}
.done {
  font-weight: bold;
  color: #28a745;
}
.link:hover {
  color: #5B6D80;
  cursor: pointer;
  text-decoration: underline;
}
th {
  position: -webkit-sticky !important;
  top: 0;
  z-index: 2;
}
.jumbotron {
  background-color: #fdfdfd;
  box-shadow: 8px 4px 3px 1px rgba(0, 0, 0, 0.02);
}
.search-off {
  font-size: 2rem;
  margin-left: 5%;
}
.display-4 {
  font-size: 1.2rem;
  color: #275fa1;
}
/*# sourceMappingURL=table-consulting-progress.component-HJB4U67S.css.map */
