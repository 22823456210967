<div class="d-flex justify-content-between align-items-center mb-5 mt-2">
    <finstein-search-all-table
        [table]="optinNotEligibleList"
        [rows]="items"
        searchPlaceholder="employees.search-items"
    ></finstein-search-all-table>

    <button
        type="button"
        class="btn btn-outline-primary rounded d-inline-flex"
        (click)="sendToDataExport()"
        [disabled]="isViewMode || itemsSelected.length === 0"
        translate
    >
        optins.back-to-data-export
    </button>
</div>

<hr />

<ngx-datatable
    #optinNotEligibleList
    class="material expandable"
    columnMode="force"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="60"
    [rows]="items"
    [loadingIndicator]="loadingIndicator"
    [selected]="itemsSelected"
    [selectionType]="SelectionType.checkbox"
    [selectAllRowsOnPage]="true"
    (select)="onSelectItem($event)"
>
    <ngx-datatable-column
        [width]="15"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        [headerCheckboxable]="!isViewMode"
        [checkboxable]="!isViewMode"
        cellClass="datatable-body-cell-checkbox"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'users.firstName' | translate"
        prop="firstName"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'users.lastName' | translate"
        prop="lastName"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.email' | translate"
        prop="email"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'optins.birthday' | translate"
        prop="birthday"
    ></ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.status' | translate"
        cellClass="d-inline-flex align-items-center justify-content-center"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <finstein-datatable-status-column
                [row]="row"
            ></finstein-datatable-status-column>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
        [name]="'common.actions' | translate"
        [sortable]="false"
        [draggable]="false"
        [canAutoResize]="true"
        cellClass="datatable-body-cell-buttons"
    >
        <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="row align-items-center">              
                <finstein-datatable-buttons-column
                    [buttons]="buttons"
                    [row]="row"
                ></finstein-datatable-buttons-column>
            </div>
        </ng-template>
    </ngx-datatable-column>
</ngx-datatable>

<finstein-search-column
    [table]="optinNotEligibleList"
    [rows]="items"
></finstein-search-column>

<finstein-datatable-suport [table]="optinNotEligibleList">
</finstein-datatable-suport>
