import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BaseModalModule } from 'src/app/core/components/base-modal/base-modal.module';
import { PlayerButtonModule } from 'src/app/core/components/player-button/player-button.module';
import { FinsteinTooltipModule } from 'src/app/core/directives/finstein-tooltip/finstein-tooltip.module';
import { DatatableUtilsModule } from 'src/app/shared/components/datatable-utils/datatable-utils.module';
import { TableConsultingProgressModule } from 'src/app/shared/components/table-consulting-progress/table-consulting-progress.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { InfoDialogModule } from '../../../core/components/info-dialog/info-dialog.module';
import { EllipsifyModule } from '../../../core/directives/ellipsify/ellipsify.module';
import { DragAndDropUploadFileModule } from '../../../shared/components/drag-and-drop-upload-file/drag-and-drop-upload-file.module';
import { canActivate } from '../../cross-profile/authentication/auth-guard.service';
import { CanDeactivateGuard } from '../../cross-profile/authentication/can-deactivate-guard.service';
import { BookkeepingKeyService } from '../bookkeeping-suggestions/bookkeeping-keys/services/bookkeeping-key.service';
import { ChooseImportationFileComponent } from './choose-importation-file/choose-importation-file.component';
import { OptinFormComponent } from './form/optin-form.component';
import { AddisonImportationStrategy } from './importation-strageties/addison-importation-strategy';
import { DatevImportationStrategy } from './importation-strageties/datev-importation-strategy';
import { EdlohnImportationStrategy } from './importation-strageties/edlohn-importation-strategy';
import { LexwareImportationStrategy } from './importation-strageties/lexware-importation-strategy';
import { LodasImportationStrategy } from './importation-strageties/lodas-importation-strategy';
import { SageImportationStrategy } from './importation-strageties/sage-importation-strategy';
import { OptinsListApprovedComponent } from './list/approved/optins-list-approved.component';
import { OptinsListDataCompletedComponent } from './list/data-completed/optins-list-data-completed.component';
import { OptinsListNotEligibleComponent } from './list/not-eligible/optins-list-not-eligible.component';
import { OptinsListComponent } from './list/optins-list.component';
import { PendentProgressModalComponent } from './list/pendent-progress-modal/pendent-progress-modal.component';
import { OptinsListPendentComponent } from './list/pendent/optins-list-pendent.component';
import { NotEligibleListModalComponent } from './not-eligible-list-modal/not-eligible-list-modal.component';
import { RequiredSettingsComponent } from './required-settings/required-settings.component';
import { ViewImportDataModule } from 'src/app/core/components/view-import-data/view-import-data.module';

const routes: Routes = [
    {
        path: '',
        canActivate: [canActivate],
        data: {
            permissions: [],
        },
        canDeactivate: [CanDeactivateGuard],
        component: OptinsListComponent,
    },
    {
        path: 'details/:id',
        canActivate: [canActivate],
        data: {
            isEditMode: false,
            isViewMode: true,
            permissions: [],
        },
        component: OptinFormComponent,
    },
    {
        path: 'update/:id',
        canActivate: [canActivate],
        canDeactivate: [CanDeactivateGuard],
        data: {
            isEditMode: true,
            isViewMode: false,
            permissions: [],
        },
        component: OptinFormComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        ClipboardModule,
        FinsteinTooltipModule,
        MatStepperModule,
        MatExpansionModule,
        MatIconModule,
        MatDatepickerModule,
        EllipsifyModule,
        NgbDropdownModule,
        DragAndDropUploadFileModule,
        TableConsultingProgressModule,
        MatRippleModule,
        PlayerButtonModule,
        NgCircleProgressModule,
        NgxSpinnerModule,
        NgxDatatableModule,
        DatatableUtilsModule,
        MatTooltipModule,
        NgbPopoverModule,
        InfoDialogModule,
        BaseModalModule,
        ViewImportDataModule
    ],
    declarations: [
        OptinsListComponent,
        OptinFormComponent,
        ChooseImportationFileComponent,
        RequiredSettingsComponent,
        OptinsListPendentComponent,
        OptinsListApprovedComponent,
        OptinsListDataCompletedComponent,
        PendentProgressModalComponent,
        OptinsListNotEligibleComponent,
        NotEligibleListModalComponent
    ],
    providers: [
        // AngularFireFunctions,
        LexwareImportationStrategy,
        DatevImportationStrategy,
        LodasImportationStrategy,
        EdlohnImportationStrategy,
        SageImportationStrategy,
        BookkeepingKeyService,
        AddisonImportationStrategy
    ]
})
export class OptinsModule {}
