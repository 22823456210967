import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { catchError, map, tap } from 'rxjs/operators';
import { ButtonAction } from 'src/app/shared/components/datatable-utils/components/buttons-column/buttons-column.component';
import {
    EMPLOYEES_COLLECTION,
    OP_EQUALS,
    OP_IN,
    REMOVE_OPTIN_FUNCTION,
    STATUS_INACTIVE,
} from '../../../../../app.constants';
import { BaseListComponent } from '../../../../../core/abstractions/base-list.component';
import { EmployeeStatusEnum } from '../../../../../shared/enums/employee-status.enum';
import { FilterModel } from '../../../../../shared/models/filter.model';
import { OptinsService } from '../../optins.service';
import { MatDialog } from '@angular/material/dialog';
import { NotEligibleListModalComponent } from '../../not-eligible-list-modal/not-eligible-list-modal.component';

const ONLY_NO_CONVERSION_ERROR = [
    'messages.employee.minimum-wage-not-reached-value',
    'messages.employee.maximum-age-to-use-the-consultation-process',
];

const ERROR_VIDEOS = [
    'employee-with-leaving-date',
    'minimum-wage-not-reached-value'
];
@Component({
    selector: 'app-optins-list-not-eligible',
    templateUrl: './optins-list-not-eligible.component.html',
    styleUrls: ['./optins-list-not-eligible.component.scss'],
})
export class OptinsListNotEligibleComponent
    extends BaseListComponent
    implements OnInit, OnDestroy
{
    @ViewChild('optinNotEligibleList') table: DatatableComponent;

    @Input() isViewMode = false;

    errorVideoList = [];

    buttons: Array<ButtonAction> = [
        {
            icon: 'dangerous',
            onClick: (item) => this.openNotEligibleListDialog(item),
            tooltip: 'tooltips.check-problems-in-optin',
            isVisible: (item) => !!item.notEligibleStatusReasons && item.notEligibleStatusReasons.length > 0
        },
        {
            icon: 'visibility',
            onClick: (item) => this.viewNotEligibleOptin(item),
            tooltip: 'tooltips.view',
        },
        {
            icon: 'delete',
            onClick: (item) => this.removeOptin(item),
            isDisabled: (item) => this.isViewMode,
            tooltip: 'tooltips.delete',
            confirmActionBeforeClick: (item: any, isDisabled?: boolean) => true,
            getConfirmActionText: (item: any, isDisabled?: boolean) =>
                item.status !== STATUS_INACTIVE
                    ? 'operations.disable.confirmation'
                    : 'operations.enable.confirmation',
        },
    ];

    constructor(
        private optinService: OptinsService,
        private dialog: MatDialog
    ) {
        super();
    }

    sendToDataExport() {
        this.spinner.show();
        if (this.itemsSelected.length && !this.isViewMode) {
            const employeesToUpdate = this.itemsSelected.map(
                (optin) => optin.id
            );
            this.optinService
                .sendOptinToDataExport(employeesToUpdate)
                .subscribe({
                    next: () => {
                        this.notification.success(
                            'messages.success-send-optins-to-data-export'
                        );
                        this.itemsSelected = [];
                        this.spinner.hide();
                    },
                    error: () => {
                        this.notification.error(
                            'messages.error-send-optins-to-data-export'
                        );
                        this.spinner.hide();
                    },
                });
        }
    }

    openNotEligibleListDialog(item) {
        this.dialog
            .open(NotEligibleListModalComponent, {
                panelClass: 'curved-modal',
                minWidth: '35vw',
                data: {
                    items: item.notEligibleStatusReasons,
                },
            })
            .afterClosed();
    }

    removeOptin(item) {
        this.spinner.show();
        if (!this.isViewMode) {
            this.optinService
                .removeOptin(item.id)
                .pipe(
                    map((data) => data.message),
                    tap(() => this.spinner.hide()),
                    catchError(() => this.spinner.hide())
                )
                .subscribe({
                    next: () => this.notification.disableSuccess(),
                    error: (err) => {
                        if (err) {
                            this.notification.error(err);
                            this.fireLogginService.sendErrorLog(
                                `An error occurred while disabling optin with function [${REMOVE_OPTIN_FUNCTION}], details: ${err}`
                            );
                        } else {
                            this.notification.error(
                                `An error occurred while updating optin status`
                            );
                            this.fireLogginService.sendErrorLog(
                                `An error occurred while disabling optin with function [${REMOVE_OPTIN_FUNCTION}].`
                            );
                        }
                    },
                });
        }
    }

    getFilter() {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_IN,
                value: [EmployeeStatusEnum.NOT_ELIGIBLE_FOR_OPTIMIZATION],
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: this.userService.companyId,
            },
        ];
        return filter;
    }

    getCollectionURL() {
        return EMPLOYEES_COLLECTION;
    }

    getRouterURL() {
        return 'optins';
    }

    getErrorTitle(error: string): string {
        const reason = this.translate(error);
        return this.translate('messages.employee.not-eligible.title', {
            reason,
        });
    }

    getErrorText(error: string) {
        return ONLY_NO_CONVERSION_ERROR.includes(error)
            ? 'messages.employee.not-eligible.no-conversion-text'
            : 'messages.employee.not-eligible.no-conversion-no-increase-text';
    }

    getErrorContactText(error: string) {
        return ERROR_VIDEOS.includes(error.split('.').at(-1)) ?
            'messages.employee.not-eligible.contact-text-with-video' : 'messages.employee.not-eligible.contact-text';
    }

    checkIfErrorHasVideo(error: string) {
        return ERROR_VIDEOS.includes(error.split('.').at(-1));
    }

    protected readonly SelectionType = SelectionType;

    viewNotEligibleOptin(item) {
        this.navigate([this.getRouterURL(), 'details', item.id]);
    }
}
