<div class="position-relative" style="min-height: 400px;">
    <ngx-spinner
        [zIndex]="1"
        name="table-spinner"
        bdColor="rgba(0,0,0,0.02)"
        color="#275FA1"
        size="medium"
        type="ball-clip-rotate"
        [fullScreen]="false">
    </ngx-spinner>

    <ng-container *ngIf="items && items.length > 0; then table; else empty"></ng-container>

    <ng-template #empty>
        <div *ngIf="items !== undefined" class="jumbotron jumbotron-fluid pl-4 fade-in">
            <div class="container d-flex align-items-center flex-column">
                <h1 class="display-4 d-flex align-items-center text-nowrap" translate>common.no-search-elements
                    <i class="material-icons search-off">search_off</i>
                </h1>
            </div>
        </div>
    </ng-template>

    <ng-template #table>
        <div class="app-table row no-margin fade-in">
            <table matSort class="table dataTable table-vmiddle table-borderless"
                   (matSortChange)="onChangeSort($event)">
                <thead class="header-table">
                <tr>
                    <ng-container *ngFor="let column of columns; let index = index">
                        <th scope="col"
                            [id]="column.property"
                            [mat-sort-header]="column.property || column.progressProperty"
                            [disabled]="!column.sortable">
                            {{ column.label | translate }}
                        </th>
                    </ng-container>
                    <th scope="col"></th>
                </tr>
                </thead>

                <tbody>
                <ng-container *ngFor="let e of items; let index = index">
                    <ng-container *ngIf="e | async as item">
                        <tr *ngIf="verifyIsChecked? isChecked(item): true">
                            <td *ngFor="let column of columns;">
                                <div *ngIf="column.property" (click)="onClickValue(item, column)"
                                     [ngClass]="getCssClass(column, item)" translate>
                                <span
                                    *ngIf="!hasActiveColumn(column) && !column.input && !column.taggerInput && !column.select"
                                    appEllipsify>
                                    {{getColumnValue(item, column)}}
                                </span>
                                </div>
                                <!-- CIRCLE PROGRESS BAR -->
                                <div *ngIf="column.progressProperty" class="cursor-default"
                                     [finsteinTooltip]="item.progressTooltipMessage" translate>
                                    <circle-progress [percent]="item[column.progressProperty]"></circle-progress>
                                </div>
                            </td>

                            <!-- ACTION BUTTONS -->
                            <td class="actions">
                                <app-approve-pending-changes *ngIf="hasPendentApproval(item); else actionButtons"
                                                             [item]="item"
                                                             (details)="onApprovalDetails(item)"
                                                             [permissionToApprove]="permissionToApprove">
                                </app-approve-pending-changes>
                                <ng-template #actionButtons>
                                    <button *ngIf="hasEditAction()"
                                            id="edit"
                                            class="btn-action"
                                            (click)="onEdit(item.id)"
                                            [disabled]="item.default || item.status === STATUS_PENDENT"
                                            [matTooltip]="'tooltips.edit' | translate"
                                            matRipple>
                                        <i class="material-icons">create</i>
                                    </button>
                                    <button *ngIf="item.status === WAITING_CONTRACT_SIGNATURE"
                                            id="reset-and-resend-employee-to-cop"
                                            class="btn-action"
                                            (click)="onResendEmployeeToCop(item)"
                                            [matTooltip]="'tooltips.resend-employee-to-cop' | translate"
                                            matRipple>
                                        <i class="material-icons">restart_alt</i>
                                    </button>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>
<div class="row justify-content-center table-pagination mt-5">
    <app-pagination [totalItems]="totalItems" [currentPage]="currentPage"
                    (paginationChange)="onPaginationChanged($event)">
    </app-pagination>
</div>
