/* src/app/modules/company/optins/not-eligible-list-modal/not-eligible-list-modal.component.scss */
.list {
  display: flex;
  align-items: center;
  justify-content: left;
}
.modal-container {
  width: 450px;
}
.danger-icon {
  color: #dc3545;
}
.error-contact-text {
  display: inline-block;
}
.error-contact-text .video-button {
  position: absolute;
  margin-left: 10px;
  cursor: pointer;
}
.error-contact-text .video-button ::ng-deep .player-button i {
  font-size: small !important;
}
/*# sourceMappingURL=not-eligible-list-modal.component-BUH52QPE.css.map */
